/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { t, SupersetClient } from '@superset-ui/core'; // ORI-112923 CHANGED: add SupersetClient
import ErrorMessageWithStackTrace from 'src/components/ErrorMessage/ErrorMessageWithStackTrace';
import { safeStringify } from '../../utils/safeStringify'; // ORI-112923 ADDED

const propTypes = {
  children: PropTypes.node.isRequired,
  onError: PropTypes.func,
  showMessage: PropTypes.bool,
};
const defaultProps = {
  onError: () => {},
  showMessage: true,
};

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, info: null };
  }

  componentDidCatch(error, info) {
    if (this.props.onError) this.props.onError(error, info);
    this.setState({ error, info });

    // ORI-112923 ADDED
    const events = [
      {
        event_name: 'runtime_error',
        event_type: 'error',
        path: window.location.pathname,
        message: error.message,
        stack: error.stack,
        ts: Date.now(),
      },
    ];

    const formData = new FormData();
    formData.append('events', safeStringify(events));
    if (SupersetClient.getGuestToken()) {
      // if we have a guest token, we need to send it for auth via the form
      formData.append('guest_token', SupersetClient.getGuestToken());
    }

    fetch('/superset/log/?explode=events', {
      method: 'POST',
      body: formData,
    })
      .then(() => {
        console.log('runtime log upload success');
      })
      .catch(err => {
        console.log('runtime log upload error,', err);
      });
    // ORI-112923 END
  }

  render() {
    const { error, info } = this.state;
    if (error) {
      const firstLine = error.toString();
      const message = (
        <span>
          <strong>{t('Unexpected error')}</strong>
          {firstLine ? `: ${firstLine}` : ''}
        </span>
      );
      if (this.props.showMessage) {
        return (
          <ErrorMessageWithStackTrace
            subtitle={message}
            copyText={message}
            stackTrace={info ? info.componentStack : null}
          />
        );
      }
      return null;
    }
    return this.props.children;
  }
}
ErrorBoundary.propTypes = propTypes;
ErrorBoundary.defaultProps = defaultProps;
